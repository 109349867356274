/* Tooltip
---------------------------------------------------------------- */
export const tooltip = {
  initialize: function () {
    // Find tooltip
    $(".tip, .tipx").each(function () {
      // Get class
      let cls = $(this).hasClass("tipset");
      let timer = 0;

      // Not set
      if (!cls) {
        // Object
        let obj = $(this);

        // Set tip attributes
        $(this).attr("data-title", $(this).attr("title"));
        // Remove title
        $(this).attr("title", "");
        // Append
        $(this).on("mouseover", function () {
          // Clear timeout
          clearTimeout(timer);
          // Timed
          timer = setTimeout(function () {
            // Call
            tooltip.show(obj);
          }, 300);
        });
        $(this).on("mouseout", function () {
          // Clear timeout
          clearTimeout(timer);
          // Hide
          tooltip.hide(obj);
        });

        // Add class
        $(this).addClass("tipset");
      }
    });
  },
  show: function (f) {
    // Show tooltip if link is enabled
    if (!$(f).hasClass("disabled")) {
      // Get object
      let obj = document.getElementById("tooltipx");
      // Set variable
      let html = "";

      // .nav-t1 user context
      if (!obj) {
        // Set
        html += '<div class="tooltipx" id="tooltipx">';
        html += '	<div class="rel">';
        html += '		<div class="txt"></div>';
        html += '		<div class="arr"></div>';
        html += "	</div>";
        html += "</div>";

        // Append
        $("body").append(html);
      }

      // Get object positions
      let el = $(f).offset().left;
      let et = $(f).offset().top;
      let ew = $(f).outerWidth();
      let eh = $(f).outerHeight();
      // Arrow widht
      let aw = $("#tooltipx .rel .arr").outerWidth();
      // Get text
      let txt = $(f).attr("data-title");

      // Update text
      $("#tooltipx .rel .txt").html(txt);
      // Show tooltip
      $("#tooltipx").show();

      // Get tooltip dimensions
      let th = $("#tooltipx").outerHeight();
      let tw = $("#tooltipx").outerWidth();

      $("#tooltipx .rel .arr").removeAttr("style");
      // Default display (north)
      let dis = "n";

      // Display array
      let disarr = ["n", "ne", "e", "se", "s", "sw", "w", "nw"];

      // Loop to find matching class
      for (let i = 0; i < disarr.length; i++) {
        if ($(f).hasClass(disarr[i])) {
          dis = disarr[i];
          break;
        }
      }

      // Add class
      $("#tooltipx").addClass(dis);
      // New positions
      let posleft = 0,
        postop = 0,
        posarr = 0;

      // North
      if (dis === "n") {
        posleft = el + ew / 2 - tw / 2;
        postop = et - th;
      }

      // North west
      if (dis === "nw") {
        posleft = el - 6;
        postop = et - th;
        posarr = ew / 2 - aw / 2 + 6;
      }

      // North east
      if (dis === "ne") {
        posleft = el + ew - tw;
        postop = et - th;
        posarr = ew / 2 - aw / 2;
      }

      // South east
      if (dis === "se") {
        posleft = el + ew - tw;
        postop = et + eh;
        posarr = ew / 2 - aw / 2;
      }

      // South west
      if (dis === "sw") {
        posleft = el;
        postop = et + eh;
        posarr = ew / 2 - aw / 2;
      }

      // South
      if (dis === "s") {
        posleft = el + ew / 2 - tw / 2;
        postop = et + eh;
      }

      // West
      if (dis === "w") {
        posleft = el - tw;
        postop = et + eh / 2 - th / 2;
      }

      // East
      if (dis === "e") {
        posleft = el + ew;
        postop = et + eh / 2 - th / 2;
      }

      // Set position
      $("#tooltipx").css("left", posleft + "px");
      $("#tooltipx").css("top", postop + "px");

      // Arrow
      if (dis == "ne" || dis == "se") {
        $("#tooltipx .rel .arr").css("right", posarr + "px");
      } else if (dis == "sw" || dis == "nw") {
        $("#tooltipx .rel .arr").css("left", posarr + "px");
      }
    }
  },
  hide: function () {
    // Hide
    $("#tooltipx").hide();
    // Display array
    const disarr = ["n", "ne", "e", "se", "s", "sw", "w", "nw"];

    // Loop to remove classes
    for (let i = 0; i < disarr.length; i++) {
      $("#tooltipx").removeClass(disarr[i]);
    }
  },
};

/* Attach */
document.addEventListener("DOMContentLoaded", () => {
  tooltip.initialize();
});
