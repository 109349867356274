/* Fileloader
---------------------------------------------------------------- */
export const fileload = {
  load: function (filename, filetype, callback) {
    // File type
    if (filetype == "js") {
      // Append
      let s = document.createElement("script");
      s.type = "text/javascript";
      s.src = filename;
      s.async = false;
      if (typeof callback === "function") {
        s.onload = callback();
      }
      $("head").append(s);
    } else if (filetype == "css") {
      // Append
      let s = document.createElement("link");
      s.rel = "stylesheet";
      s.type = "text/css";
      s.href = filename;
      $("head").append(s);
    }
  },
};
