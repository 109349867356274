import { fileload } from "./loader";

/* global bodymovin */

/* Animations
---------------------------------------------------------------- */

export const animations = (function () {
  // Allowed?
  let fids = 0;

  return {
    initialize: function () {
      // Any animations on the page?
      if ($(".lottieanimation").length) {
        // Load file
        fileload.load(
          "https://cdn.addevent.com/web/js/lottie/lottie.5.6.5.min.js",
          "js"
        );

        // Call when script has loaded
        let bodyint = setInterval(function () {
          // Find out if "slick" exists
          if (typeof bodymovin != "undefined") {
            // Clear interval
            clearInterval(bodyint);
            // Call callback
            animations.callback();
          }
        }, 500);
      }
    },
    callback: function () {
      // Variables
      let st = $(window).scrollTop();

      // If we are not at top, then call observe
      if (st > 0) {
        animations.observe();
      } else {
        // Timed activate
        setTimeout(function () {
          animations.observe();
        }, 6000);
      }

      // Observe
      $(window).scroll(function () {
        animations.observe();
      });
    },
    observe: function () {
      // Variables
      let st = $(window).scrollTop();
      let wh = $(window).height();

      // Find lottie animations
      $(".lottieanimation:not(.lottieloaded)").each(function () {
        // Element top
        let elmt = $(this).offset().top;
        // Load?
        if (st > elmt - wh) {
          // Set loaded
          $(this).addClass("lottieloaded");
          // Get the source
          let src = $(this).attr("data-source");
          // Add
          fids++;
          // Set the ID
          $(this).attr("id", "lotani" + fids);

          // Get the element
          let elm = document.getElementById("lotani" + fids);

          // Get elements dimensions
          let ew = $(elm).outerWidth();
          let eh = $(elm).outerHeight();

          // Set min height on element
          $(elm).css("min-width", ew + "px");
          $(elm).css("min-height", eh + "px");

          // Remove existing contents
          $(this).find("*").addClass("hideimportant");

          // Load animation
          bodymovin.loadAnimation({
            container: elm,
            path: "https://cdn.addevent.com/web/js/lottie/json/" + src,
            renderer: "svg",
            loop: true,
            autoplay: true,
          });

          // Remove min height
          setTimeout(function () {
            $(elm).css("min-height", "");
          }, 1000);
        }
      });
    },
  };
})();

// Attach
$(document).ready(function () {
  animations.initialize();
});
