/* Code markup
---------------------------------------------------------------- */
import { addresslookup } from "./addresslookup";
import { cookies } from "./cookies";
import { popselt1 } from "./popup";
import { _tooltip } from "./tooltip";

export const codemarkup = {
  // Variables
  codeobj: {},

  initialize: function () {
    // Append
    $(".code-t1 .more .lnk").on("click", function () {
      codemarkup.show(this);
      return false;
    });
    $(".code-t1 .more .drop a").on("click", function () {
      codemarkup.examples(this);
      return false;
    });
    $(".code-t1 .nav .inm a").on("click", function () {
      codemarkup.codeviewtoogle(this);
      return false;
    });
    $(".markup-t1").on("click", function () {
      codemarkup.selectable(this);
      return false;
    });
    $(".selectabletext").on("blur", function () {
      codemarkup.selectableinputblur(this);
    });
    $(".selectabletext").on("click", function () {
      codemarkup.selectableinputclick(this);
    });

    // JSON cleanup
    this.cleanup();
    // JSON markup
    this.jsonmarkup();
    // Markup code
    this.markup();
  },
  cleanup: function () {
    // Exists?
    if ($(".jsonmarkup").length) {
      // Get code markups
      $(".jsonmarkup code").each(function () {
        // Get content
        let cod = $(this).html();
        // Replace HTML characters
        cod = cod.replace(/&nbsp;/gi, "");
        cod = cod.replace(/\s+/, "");

        // Update
        $(this).text(cod);
      });
    }
  },
  markup: function () {
    // Exists?
    if ($(".codemarkup").length) {
      // Get code markups
      $(".codemarkup:not(.jsonmarkup)").each(function () {
        // Get content
        let ctx = $(this).html();
        // Get the line number count
        let num = ctx.split(/\n/).length - 1;

        // One line wraps
        if (num == "0" || num == "1") {
          // Add
          $(this).addClass("inline");
          // Add auto avoid class to parent
          $(this).parent(".exmctn").first().addClass("autoheight");
          // Set
          num = 1;
        }

        // Line number html
        let lhtml = "";
        // Loop
        for (let j = 0; j < num; j++) {
          // Collect
          lhtml += '<span class="num">' + (j + 1) + "</span>";
        }

        // Get content
        let str = ctx;
        // Replace tabs with spaces
        str = str.replace(/\t/g, "");

        // Get class
        let jso_cls = $(this).hasClass("json");

        // Normal markup or JSON
        if (!jso_cls) {
          // Values between ""
          str = str.replace(/"([^"]+)"/g, '"<span class="m5">$1</span>"');
          str = str.replace(/'([^']+)'/g, "'<span class=\"m5\">$1</span>'");

          // Rest
          str = str.replace(
            /&lt;html&gt;/gi,
            '&lt;<span class="m4">html</span>&gt;'
          );
          str = str.replace(
            /&lt;\/html&gt;/gi,
            '&lt;/<span class="m4">html</span>&gt;'
          );
          str = str.replace(
            /&lt;head&gt;/gi,
            '&lt;<span class="m4">head</span>&gt;'
          );
          str = str.replace(
            /&lt;\/head&gt;/gi,
            '&lt;/<span class="m4">head</span>&gt;'
          );
          str = str.replace(
            /&lt;style /gi,
            '&lt;<span class="m4">style</span> '
          );
          str = str.replace(
            /&lt;\/style&gt;/gi,
            '&lt;/<span class="m4">style</span>&gt;'
          );
          str = str.replace(
            /&lt;body&gt;/gi,
            '&lt;<span class="m4">body</span>&gt;'
          );
          str = str.replace(
            /&lt;\/body&gt;/gi,
            '&lt;/<span class="m4">body</span>&gt;'
          );
          str = str.replace(
            /&lt;script/gi,
            '&lt;<span class="m4">script</span>'
          );
          str = str.replace(
            /&lt;\/script&gt;/gi,
            '&lt;<span class="m4">/script</span>&gt;'
          );
          str = str.replace(/&lt;div/gi, '&lt;<span class="m4">div</span>');
          str = str.replace(
            /&lt;\/div&gt;/gi,
            '&lt;/<span class="m4">div</span>&gt;'
          );
          str = str.replace(/&lt;span/gi, '&lt;<span class="m4">span</span>');
          str = str.replace(
            /&lt;\/span&gt;/gi,
            '&lt;/<span class="m4">span</span>&gt;'
          );
          str = str.replace(/type=/gi, '<span class="m2">type</span>=');
          str = str.replace(/src=/gi, '<span class="m2">src</span>=');
          str = str.replace(/ async/gi, '<span class="m2"> async</span>');
          str = str.replace(/ defer/gi, '<span class="m2"> defer</span>');
          str = str.replace(/\/\*/gi, '<span class="m9c">/*');
          str = str.replace(/\*\//gi, "*/</span>");
          str = str.replace(/function/gi, '<span class="m4">function</span>');
          str = str.replace(/&lt;!--/gi, '<span class="m9c">&lt;!--');
          str = str.replace(/--&gt;/gi, "--&gt;</span>");

          // Replace timezone
          str = str.replace(
            /America\/Los_Angeles/gi,
            '<div class="timezonechg" title="Change Timezone">America/Los_Angeles</div>'
          );
          str = str.replace(
            /ic14/gi,
            '<div class="calendarchg" title="Select">ic14</div>'
          );
          str = str.replace(
            /\$ curl/gi,
            '<span class="markup-t5">$ curl</span>'
          );
        } else {
          str = str.replace(/\{/gi, '<span class="m6">{</span>');
          str = str.replace(/\}/gi, '<span class="m6">}</span>');
          str = str.replace(/\[/gi, '<span class="m6">[</span>');
          str = str.replace(/\]/gi, '<span class="m6">]</span>');
          str = str.replace(/\"id\"/gi, '<span class="m7">"id"</span>');
          str = str.replace(/\"meta\"/gi, '<span class="m7">"meta"</span>');
          str = str.replace(/\"code\"/gi, '<span class="m7">"code"</span>');
          str = str.replace(
            /\"calendar\"/gi,
            '<span class="m7">"calendar"</span>'
          );
          str = str.replace(
            /\"calendars\"/gi,
            '<span class="m7">"calendars"</span>'
          );
          str = str.replace(
            /\"uniquekey\"/gi,
            '<span class="m7">"uniquekey"</span>'
          );
          str = str.replace(
            /\"calendarname\"/gi,
            '<span class="m7">"calendarname"</span>'
          );
          str = str.replace(/\"title\"/gi, '<span class="m7">"title"</span>');
          str = str.replace(
            /\"description\"/gi,
            '<span class="m7">"description"</span>'
          );
          str = str.replace(
            /\"timezone\"/gi,
            '<span class="m7">"timezone"</span>'
          );
          str = str.replace(
            /\"followers_active\"/gi,
            '<span class="m7">"followers_active"</span>'
          );
          str = str.replace(
            /\"followers_total\"/gi,
            '<span class="m7">"followers_total"</span>'
          );
          str = str.replace(
            /\"events_total\"/gi,
            '<span class="m7">"events_total"</span>'
          );
          str = str.replace(
            /\"main_calendar\"/gi,
            '<span class="m7">"main_calendar"</span>'
          );
          str = str.replace(
            /\"date_create\"/gi,
            '<span class="m7">"date_create"</span>'
          );
          str = str.replace(
            /\"date_modified\"/gi,
            '<span class="m7">"date_modified"</span>'
          );
          str = str.replace(/\"rrule\"/gi, '<span class="m7">"rrule"</span>');
          str = str.replace(/\"rsvp\"/gi, '<span class="m7">"rsvp"</span>');
          str = str.replace(
            /\"rsvp_require\"/gi,
            '<span class="m7">"rsvp_require"</span>'
          );
          str = str.replace(
            /\"rsvp_template_id\"/gi,
            '<span class="m7">"rsvp_template_id"</span>'
          );
          str = str.replace(/\"paging\"/gi, '<span class="m7">"paging"</span>');
          str = str.replace(
            /\"previous\"/gi,
            '<span class="m7">"previous"</span>'
          );
          str = str.replace(/\"next\"/gi, '<span class="m7">"next"</span>');
          str = str.replace(/\"status\"/gi, '<span class="m7">"status"</span>');
          str = str.replace(
            /\"events_count\"/gi,
            '<span class="m7">"events_count"</span>'
          );
          str = str.replace(/\"events\"/gi, '<span class="m7">"events"</span>');
          str = str.replace(/\"event\"/gi, '<span class="m7">"event"</span>');
          str = str.replace(
            /\"location\"/gi,
            '<span class="m7">"location"</span>'
          );
          str = str.replace(
            /\"organizer\"/gi,
            '<span class="m7">"organizer"</span>'
          );
          str = str.replace(
            /\"organizer_email\"/gi,
            '<span class="m7">"organizer_email"</span>'
          );
          str = str.replace(
            /\"date_start\"/gi,
            '<span class="m7">"date_start"</span>'
          );
          str = str.replace(
            /\"date_start_time\"/gi,
            '<span class="m7">"date_start_time"</span>'
          );
          str = str.replace(
            /\"date_start_ampm\"/gi,
            '<span class="m7">"date_start_ampm"</span>'
          );
          str = str.replace(
            /\"date_start_unix\"/gi,
            '<span class="m7">"date_start_unix"</span>'
          );
          str = str.replace(
            /\"date_end\"/gi,
            '<span class="m7">"date_end"</span>'
          );
          str = str.replace(
            /\"date_end_time\"/gi,
            '<span class="m7">"date_end_time"</span>'
          );
          str = str.replace(
            /\"date_end_ampm\"/gi,
            '<span class="m7">"date_end_ampm"</span>'
          );
          str = str.replace(
            /\"date_end_unix\"/gi,
            '<span class="m7">"date_end_unix"</span>'
          );
          str = str.replace(
            /\"all_day_event\"/gi,
            '<span class="m7">"all_day_event"</span>'
          );
          str = str.replace(
            /\"date_format\"/gi,
            '<span class="m7">"date_format"</span>'
          );
          str = str.replace(
            /\"reminder\"/gi,
            '<span class="m7">"reminder"</span>'
          );
          str = str.replace(
            /\"updated_times\"/gi,
            '<span class="m7">"updated_times"</span>'
          );
          str = str.replace(
            /\"calendar_id\"/gi,
            '<span class="m7">"calendar_id"</span>'
          );
          str = str.replace(
            /\"calendar_uniquekey\"/gi,
            '<span class="m7">"calendar_uniquekey"</span>'
          );
          str = str.replace(
            /\"calendar_title\"/gi,
            '<span class="m7">"calendar_title"</span>'
          );
          str = str.replace(
            /\"calendar_name\"/gi,
            '<span class="m7">"calendar_name"</span>'
          );
          str = str.replace(
            /\"calendar_followers_active\"/gi,
            '<span class="m7">"calendar_followers_active"</span>'
          );
          str = str.replace(
            /\"calendar_followers_total\"/gi,
            '<span class="m7">"calendar_followers_total"</span>'
          );
          str = str.replace(
            /\"calendar_events_total\"/gi,
            '<span class="m7">"calendar_events_total"</span>'
          );
          str = str.replace(
            /\"calendar_date_create\"/gi,
            '<span class="m7">"calendar_date_create"</span>'
          );
          str = str.replace(
            /\"calendar_date_modified\"/gi,
            '<span class="m7">"calendar_date_modified"</span>'
          );
          str = str.replace(/\"data\"/gi, '<span class="m7">"data"</span>');
          str = str.replace(/\"label\"/gi, '<span class="m7">"label"</span>');
          str = str.replace(/\"offset\"/gi, '<span class="m7">"offset"</span>');
          str = str.replace(
            /\"eventname\"/gi,
            '<span class="m7">"eventname"</span>'
          );
          str = str.replace(/\"client\"/gi, '<span class="m7">"client"</span>');
          str = str.replace(/\"start\"/gi, '<span class="m7">"start"</span>');
          str = str.replace(/\"end\"/gi, '<span class="m7">"end"</span>');
          str = str.replace(
            /\"duration\"/gi,
            '<span class="m7">"duration"</span>'
          );
          str = str.replace(
            /\"reference\"/gi,
            '<span class="m7">"reference"</span>'
          );
          str = str.replace(
            /\"service\"/gi,
            '<span class="m7">"service"</span>'
          );
          str = str.replace(
            /\"template\"/gi,
            '<span class="m7">"template"</span>'
          );
          str = str.replace(
            /\"template_id\"/gi,
            '<span class="m7">"template_id"</span>'
          );
          str = str.replace(/\"alarm\"/gi, '<span class="m7">"alarm"</span>');
          str = str.replace(
            /\"recurring\"/gi,
            '<span class="m7">"recurring"</span>'
          );
          str = str.replace(/\"uid\"/gi, '<span class="m7">"uid"</span>');
          str = str.replace(/\"method\"/gi, '<span class="m7">"method"</span>');
          str = str.replace(/\"link\"/gi, '<span class="m7">"link"</span>');
          str = str.replace(/\"clicks\"/gi, '<span class="m7">"clicks"</span>');
          str = str.replace(
            /\"created\"/gi,
            '<span class="m7">"created"</span>'
          );
          str = str.replace(
            /\"modified\"/gi,
            '<span class="m7">"modified"</span>'
          );
          str = str.replace(/\"action\"/gi, '<span class="m7">"action"</span>');
          str = str.replace(
            /\"calname\"/gi,
            '<span class="m7">"calname"</span>'
          );
          str = str.replace(
            /\"custom_data\"/gi,
            '<span class="m7">"custom_data"</span>'
          );
          str = str.replace(
            /\"link_short\"/gi,
            '<span class="m7">"link_short"</span>'
          );
          str = str.replace(
            /\"link_long\"/gi,
            '<span class="m7">"link_long"</span>'
          );
          str = str.replace(
            /\"calendar_link_short\"/gi,
            '<span class="m7">"calendar_link_short"</span>'
          );
          str = str.replace(
            /\"calendar_link_long\"/gi,
            '<span class="m7">"calendar_link_long"</span>'
          );
          str = str.replace(/\"unique\"/gi, '<span class="m7">"unique"</span>');
          str = str.replace(
            /\"rsvp_count\"/gi,
            '<span class="m7">"rsvp_count"</span>'
          );
          str = str.replace(
            /\"summary\"/gi,
            '<span class="m7">"summary"</span>'
          );
          str = str.replace(
            /\"rsvp_count\"/gi,
            '<span class="m7">"rsvp_count"</span>'
          );
          str = str.replace(/\"going\"/gi, '<span class="m7">"going"</span>');
          str = str.replace(/\"maybe\"/gi, '<span class="m7">"maybe"</span>');
          str = str.replace(
            /\"cant_go\"/gi,
            '<span class="m7">"cant_go"</span>'
          );
          str = str.replace(/\"rsvps\"/gi, '<span class="m7">"rsvps"</span>');
          str = str.replace(/\"header\"/gi, '<span class="m7">"header"</span>');
          str = str.replace(
            /\"attending\"/gi,
            '<span class="m7">"attending"</span>'
          );
          str = str.replace(
            /\"attending_code\"/gi,
            '<span class="m7">"attending_code"</span>'
          );
          str = str.replace(/\"name\"/gi, '<span class="m7">"name"</span>');
          str = str.replace(/\"email\"/gi, '<span class="m7">"email"</span>');
          str = str.replace(/\"ip\"/gi, '<span class="m7">"ip"</span>');
          str = str.replace(/\"city\"/gi, '<span class="m7">"city"</span>');
          str = str.replace(/\"region\"/gi, '<span class="m7">"region"</span>');
          str = str.replace(
            /\"country\"/gi,
            '<span class="m7">"country"</span>'
          );
          str = str.replace(/\"postal\"/gi, '<span class="m7">"postal"</span>');
          str = str.replace(/\"userid\"/gi, '<span class="m7">"userid"</span>');
          str = str.replace(
            /\"createdate\"/gi,
            '<span class="m7">"createdate"</span>'
          );
          str = str.replace(
            /\"modifydate\"/gi,
            '<span class="m7">"modifydate"</span>'
          );
          str = str.replace(
            /\"attendees\"/gi,
            '<span class="m7">"attendees"</span>'
          );
        }

        // Append frames
        $(this).html(
          '<div class="lines"><div class="bfnum">' +
            lhtml +
            '</div></div><div class="code"><div class="inn">' +
            str +
            '</div></div><div class="clr"></div>'
        );

        // Show
        $(this).show();
      });

      // Get JSON code markups
      $(".jsonmarkup").each(function () {
        // Get content
        let ctx = $(this).html();
        // Get the line number count
        let num = ctx.split(/\n/).length;

        // One line wraps
        if (num == "0" || num == "1") {
          // Add
          $(this).addClass("inline");
          // Add auto avoid class to parent
          $(this).parent(".exmctn").first().addClass("autoheight");
          // Set
          num = 1;
        }

        // Line number html
        let lhtml = "";

        // Loop
        for (let j = 0; j < num; j++) {
          // Collect
          lhtml += '<span class="num">' + (j + 1) + "</span>";
        }

        // Get content
        let str = ctx;
        // Append frames
        $(this).html(
          '<div class="lines"><div class="bfnum">' +
            lhtml +
            '</div></div><div class="code"><div class="inn">' +
            str +
            '</div></div><div class="clr"></div>'
        );
        // Show
        $(this).show();
      });

      // Attach
      $(".codemarkup .code .inn").dblclick(function () {
        codemarkup.selectable(this);
        return false;
      });
      $(".calendarchg")
        .off("click")
        .on("click", function () {
          popselt1.show(this);
          return false;
        });

      // Attach click
      $(".timezonechg").each(function () {
        // Is set?
        if (!$(this).hasClass("isset")) {
          // Attach
          $(this)
            .off("click")
            .on("click", function () {
              addresslookup.show(this);
              return false;
            });
          // Add
          $(this).addClass("isset");
        }
      });

      // Get stored timezone cookie value
      let tzcook = cookies.read("timezone");

      // Any value?
      if (tzcook) {
        $(".timezonechg").text(tzcook);
      }

      // Get markups, set container heights
      $(".code-t1 .exmctn").each(function () {
        // Already visible?
        let exmvis = $(this).css("display");
        // Show container
        $(this).show();

        // Get objects
        let objhe = $(this).find(".example");
        let objhc = $(this).find(".codemarkup");

        // Already visible?
        let objhevis = $(objhe).css("display");
        let objhcvis = $(objhc).css("display");

        // Set visible
        $(objhe).show();
        $(objhc).show();

        // Get heights
        let _he = $(objhe).height();
        let _hc = $(objhc).height();

        // Hide containers again
        if (exmvis == "none") {
          $(this).hide();
        }
        if (objhevis == "none") {
          $(objhe).hide();
        }
        if (objhcvis == "none") {
          $(objhc).hide();
        }
      });
    }
  },
  show: function (f) {
    // Get element
    let obj = $(f);
    // Get active class
    let act = $(obj).hasClass("active");
    // Get settings
    let ctx = $(obj).attr("data-context");

    // Active? (show / hide)
    if (act) {
      // Remove active
      $(obj).removeClass("active");
      // Hide
      codemarkup.hide();
    } else {
      // Show
      $("#" + ctx).show();
      // Timed class
      setTimeout(function () {
        $("#" + ctx).addClass("active");
      }, 200);

      // Set active
      $(obj).addClass("active");
      // Set
      this.codeobj = f;
      // Unbind
      $(document).unbind(".codemenu");

      // Timed
      setTimeout(function () {
        // Bind
        $(document).bind("click.codemenu", function () {
          codemarkup.hide();
        });
      }, 200);
    }
  },
  hide: function () {
    // Remove active
    $(this.codeobj).removeClass("active");
    // Hide context menu
    $(".code-t1 .more .drop").removeClass("active");

    // Timed hide
    setTimeout(function () {
      $(".code-t1 .more .drop").hide();
    }, 200);

    // Unbind
    $(document).unbind(".codemenu");
  },
  examples: function (f) {
    // Get relation
    let rel = $(f).attr("data-rel");
    let box = $(f).attr("data-box");
    // Get example text
    let txt = $(f).text();
    // Update example text
    $("#" + box + " .more .lnk .txt").text(txt);
    // Reset selected
    $("#" + box + " .more .drop a").removeClass("selected");
    // Set selected
    $(f).addClass("selected");

    // Hide examples
    $("#" + box + " .exmctn").hide();
    // Show example
    $("#" + box + " #" + rel).show();
    // Set current
    $("#" + box).attr("data-active-id", rel);
    // Reset source/view
    $("#" + box + " .nav .inm a").removeClass("selected");
    $("#" + box + " .nav .inm a")
      .first()
      .addClass("selected");
    $("#" + box + " .code #" + rel + " .example").hide();
    $("#" + box + " .code #" + rel + " .codemarkup").show();

    // Hide drop down
    codemarkup.hide();
  },
  codeviewtoogle: function (f) {
    // Get view type
    let viw = $(f).attr("data-view");
    let box = $(f).attr("data-box");
    // Get active
    let fid = $("#" + box).attr("data-active-id");
    // Reset
    $("#" + box + " .nav .inm a").removeClass("selected");
    // Set active
    $(f).addClass("selected");
    // Hide
    $("#" + fid + " .example").hide();
    $("#" + fid + " .codemarkup").hide();
    $("#" + fid + " .jsonmarkup").hide();
    // Show
    if (viw == "code") {
      $("#" + fid + " .codemarkup").show();
    } else if (viw == "example") {
      $("#" + fid + " .example").show();
    } else if (viw == "json") {
      $("#" + fid + " .jsonmarkup").show();
    }
  },
  selectable: function (f) {
    let range = "";
    // Highlight text
    if (document.selection) {
      range = document.body.createTextRange();
      range.moveToElementText(f);
      range.select();
    } else if (window.getSelection) {
      range = document.createRange();
      range.selectNodeContents(f);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
    }
  },
  selectableinputclick: function (f) {
    if (!$(f).attr("data-selected-all")) {
      try {
        // Set
        $(f).selectionStart = 0;
        $(f).selectionEnd = $(f).value.length + 1;
        // Add atribute
        $(f).attr("data-selected-all", true);
      } catch (err) {
        // Select
        $(f).select();
        // Add atribute
        $(f).attr("data-selected-all", true);
      }
    }
  },
  selectableinputblur: function (f) {
    // Remove attribute
    if ($(f).attr("data-selected-all")) {
      // Remove
      $(f).removeAttr("data-selected-all");
    }
  },
  jsonmarkup: function () {
    // Loop through checkboxes, set check
    $(".jsonmarkup code").each(function () {
      // Allowed
      if (!$(this).hasClass("prettified")) {
        //Get content
        let con = $(this).text();
        // Parse JSON
        con = JSON.parse(con);
        // JSON Stringify
        con = JSON.stringify(con, undefined, 3);

        // Prettify
        const pre = codemarkup.jsonprettify(con);
        // Parse
        $(this).html(pre);
        // Add class
        $(this).addClass("prettified");
      }
    });
  },
  jsonprettify: function (json) {
    // Replace HTML characters
    json = json
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;");
    // Return all
    return json.replace(
      /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
      function (match) {
        // Set default
        let cls = "number";
        // Match cases, create markup
        if (/^"/.test(match)) {
          if (/:$/.test(match)) {
            cls = "key";
          } else {
            cls = "string";
          }
        } else if (/true|false/.test(match)) {
          cls = "boolean";
        } else if (/null/.test(match)) {
          cls = "null";
        }

        // Return
        return '<span class="' + cls + '">' + match + "</span>";
      }
    );
  },
};

// Attach
document.addEventListener("DOMContentLoaded", () => {
  codemarkup.initialize();
});
