"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class PlanGroupData {
    constructor(item) {
        this.id = 0;
        this.name = "";
        this.experimentId = "";
        this.planSource = "";
        if (typeof item.id !== "undefined") {
            this.id = item.id;
        }
        if (typeof item.name !== "undefined") {
            this.name = item.name;
        }
        if (typeof item.experiment_id !== "undefined") {
            this.experimentId = item.experiment_id;
        }
        if (typeof item.plan_source !== "undefined") {
            this.planSource = item.plan_source;
        }
    }
    getPlanGroupId() {
        return this.id;
    }
    getPlanGroupName() {
        return this.name;
    }
    getExperimentId() {
        return this.experimentId;
    }
    getPlanSource() {
        return this.planSource;
    }
}
exports.default = PlanGroupData;
