/* Calendars popup
---------------------------------------------------------------- */
import { _addresslookup } from "./addresslookup";

export const popselt1 = {
  initialize: function () {
    // Attach
    $(window).on("resize", function () {
      popselt1.hide();
    });
  },
  show: function (f) {
    // Remove container
    $("body").find(".popselt1ctn").remove();

    // Variables
    let html = "",
      ofw = $(f).width(),
      ofl = $(f).offset().left,
      oft = $(f).offset().top;
    // Popup
    html += '<div class="popsel-t1" id="popselt1">';
    html += '    <div class="inr">';
    html += '        <div class="px">';
    html += '            <p class="hd">Select calendar</p>';
    html += '            <div class="lst">';
    html += '	            <div class="fx">';
    html += '	            	<div class="ajx"></div>';
    html += '	            	<div class="load"></div>';
    html += "	            </div>";
    html += "            </div>";
    html +=
      '            <div class="close"><i class="material-icons">close</i></div>';
    html += "        </div>";
    html += '        <div class="arr"></div>';
    html += "    </div>";
    html += "</div>";

    // Post
    $.ajax({
      type: "GET",
      url: "/documentation/list-calendars",
      success: function (data) {
        // Remove load
        $("#popselt1 .load").hide();
        // Append
        $("#popselt1 .ajx").html(data);
        // Attach
        $("#popselt1 .lst ul li a").on("click", function () {
          popselt1.select(this);
          return false;
        });
      },
    });

    // Add container
    $("body").append(
      '<div class="popselt1ctn" style="width:' +
        ofw +
        "px;left:" +
        ofl +
        "px;top:" +
        oft +
        'px;"><div class="psctnt1">' +
        html +
        "</div></div>"
    );
    // Show
    $("#popselt1").fadeIn("fast");
    // Attach
    $("#popselt1 .close").on("click", function () {
      popselt1.hide();
      return false;
    });
    $("#popselt1").on("click", function (event) {
      event.stopPropagation();
    });
    // Unbind
    $(document).off(".popselt1popup");

    // Timed
    setTimeout(function () {
      // Bind
      $(document).on("click.popselt1popup", function () {
        popselt1.hide();
      });
    }, 200);
  },
  hide: function () {
    // Show
    $("#popselt1").fadeOut("fast");
  },
  select: function (f) {
    // Get id
    let fid = $(f).attr("data-unique");

    // Update
    $(".calendarchg").text(fid);
    // Hide
    popselt1.hide();
  },
};

// Attach
document.addEventListener("DOMContentLoaded", () => {
  popselt1.initialize();
});
